<template>
  <b-tabs>
    <b-tab title="线下指导价">
      <price-guide-offline-list/>
    </b-tab>

    <b-tab title="线上指导价">
      <price-guide-online-list/>
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import priceguideofflineStore from './priceguideofflineStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import PriceGuideOfflineList from "@/views/apps/priceguideoffline/PriceGuideOfflineList";
import PriceGuideOnlineList from "@/views/apps/priceguideonline/PriceGuideOnlineList";

export default {
  components: {
    PriceGuideOnlineList,
    PriceGuideOfflineList,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    BTabs,
    BTab,
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('priceguideoffline')) store.registerModule('priceguideoffline', priceguideofflineStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('priceguideoffline')) store.unregisterModule('priceguideoffline')
    })

    return {
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
